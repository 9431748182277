
import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

export default function setupSentry () {
  console.log('Setting up sentry…')
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    environment: process.env.NODE_ENV,
    integrations: [
      new VueIntegration({
        Vue,
        logErrors: true,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
  });
}