import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "routes" */ './views/Login.vue'),
    props: route => ({ next: route.query.next || '/clients', logout: route.query.logout || '' })
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "clients" */ './views/Clients.vue'),
  },
  {
    path: '/clients/:id',
    name: 'clients.detail',
    props: true,
    component: () => import(/* webpackChunkName: "clients" */ './views/ClientDetail.vue'),
  },
  {
    path: '/email',
    name: 'email',
    component: () => import(/* webpackChunkName: "email" */ './views/Email.vue'),
  },
  {
    path: '/licenses',
    name: 'licenses',
    component: () => import(/* webpackChunkName: "licenses" */ './views/Licenses.vue'),
  },
  {
    path: '/labels',
    name: 'labels',
    component: () => import(/* webpackChunkName: "labels" */ './views/Labels.vue'),
  },
  {
    path: '/transformations',
    name: 'transformations',
    component: () => import(/* webpackChunkName: "transformations" */ './views/Transformations.vue'),
  },
  {
    path: '/abtests',
    name: 'abtests',
    component: () => import(/* webpackChunkName: "abtests" */ './views/Abtests.vue'),
  },
  {
    path: '/machines',
    name: 'machines',
    component: () => import(/* webpackChunkName: "machines" */ './views/Machines.vue'),
  },
  {
    path: '/recordings',
    name: 'recordings',
    component: () => import(/* webpackChunkName: "recordings" */ './views/Recordings.vue'),
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import(/* webpackChunkName: "invoices" */ './views/Invoices.vue'),
  },
  {
    path: '/pricings',
    name: 'pricings',
    component: () => import(/* webpackChunkName: "pricings" */ './views/Pricings.vue'),
  },
  {
    path: '/traffic',
    name: 'traffic',
    component: () => import(/* webpackChunkName: "traffic" */ './views/Traffic.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
