import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
  mdiAccountGroup,
  mdiChartBox,
  mdiClose,
  mdiCurrencyEur,
  mdiDownload,
  mdiEye,
  mdiEyeOff,
  mdiFileCog,
  mdiHeadphones,
  mdiKey,
  mdiLabel,
  mdiLaptop,
  mdiLogin,
  mdiLogout,
  mdiMagnify,
  mdiPencil,
  mdiPlus,
  mdiTrendingUp,
  mdiRefresh,
  mdiTune,
} from '@mdi/js'

Vue.use(Vuetify);


// three shaking icons to reduce bundle size
Vue.prototype.$icons = {
  mdiAccountGroup,
  mdiChartBox,
  mdiClose,
  mdiCurrencyEur,
  mdiDownload,
  mdiFileCog,
  mdiEye,
  mdiEyeOff,
  mdiHeadphones,
  mdiKey,
  mdiLabel,
  mdiLaptop,
  mdiLogin,
  mdiLogout,
  mdiMagnify,
  mdiPencil,
  mdiPlus,
  mdiTrendingUp,
  mdiRefresh,
  mdiTune,
}

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
  }
})
