<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer clipped left app permanent>
        <v-list>
          <v-list-item to="/login" link v-if="!$store.state.authenticated">
            <v-list-item-icon>
              <v-icon v-text="$icons.mdiLogin"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Login
            </v-list-item-content>
          </v-list-item>
          <template v-if="$store.state.authenticated">
            <v-list-item to="/clients" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiAccountGroup }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Clients</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/licenses" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiKey }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Licenses</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/labels" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiLabel }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Labels</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/transformations" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiTune }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Transformations</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/abtests" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiChartBox }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>A/B Tests</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/machines" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiLaptop }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Machines</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/recordings" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiHeadphones }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Recordings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/pricings" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiCurrencyEur }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Pricings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/invoices" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiCurrencyEur }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Invoices</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/traffic" link>
              <v-list-item-icon>
                <v-icon>{{ $icons.mdiTrendingUp }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Website Traffic</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        
        <template v-slot:append v-if="$store.state.authenticated">
          <div class="pa-2">
            <v-btn block @click="$router.push('/login?logout=true')">
              Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
      <v-main>
        <router-view/>
      </v-main>
    </v-app>

  </div>
</template>
<script>
export default {
  async created () {
    if (!this.$store.state.authenticated) {
      this.$router.push('/login')
    } else {
      await this.$store.dispatch("refreshMeta")
    }
  }
}
</script>
<style lang="scss">
@import "style/_main.scss";
</style>