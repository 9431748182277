import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import setupSentry from './sentry'
import store from './store'

Vue.config.productionTip = false
if (process.env.VUE_APP_SENTRY_DSN) {
  setupSentry()
}
Vue.filter('truncate', function (text, length, suffix='') {
  text = String(text)
  if (text.length > length) {
    return text.substring(0, length) + suffix
  } else {
    return text
  }
});
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')